import styled from 'styled-components';

const Tag = styled.span`
  display: inline-block;
  margin-right: 1rem;
  padding-top: 0;
  padding-right: 0.5rem;
  padding-bottom: 0.2rem;
  padding-left: 0.5rem;
  color: ${props => props.theme.colorText};
  background-color: ${props => props.theme.colorPrimaryDark};
  border: 1px solid ${props => props.theme.colorPrimaryLight}aa;
  font-family: ${props => props.theme.fontMonospace};
  font-size: 1.2rem;
  line-height: 1.5;
  border-radius: 5px;
`;

export default Tag;

import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import MDXRenderer from 'gatsby-plugin-mdx/mdx-renderer';
import { MDXProvider } from '@mdx-js/react';
import { FaGithub } from 'react-icons/fa';

import Layout from '../components/layout';
import variables from '../styles/variables';
import BackButton from '../components/backButton';
import Tag from '../components/tag';

const Container = styled.div`
  max-width: 1200px;
`;

const TitleBarContainer = styled.div`
  margin-bottom: 5rem;
`;

const Title = styled.h1`
  font-size: 5rem;
  text-shadow: 1px 1px 0px ${props => props.theme.colorPrimaryDark};
  color: ${props => props.theme.colorPrimaryLight};
`;

const TagsContainer = styled.div`
  display: inline-block;
  padding: 2rem 0;
  margin-top: 3rem;
  border-radius: 1rem;
`;

const GitHubLink = styled.a`
  display: inline-block;
  font-size: 3rem;
  transform: translateY(1.1rem); 
  transition: color 0.2s;
  color: ${props => props.theme.colorButtonBackground};

  @media only screen and (min-width: calc(${variables.screenWidth} + 1px)) {
    &:hover {
      color: ${props => props.theme.colorSecondaryLight};
    }
  }
`;

const TimeInfo = styled.p`
  font-size: 1.2rem;
  color: ${props => props.theme.colorTextLight};
`;

const Paragraph = styled.p`
  margin: 2rem 0;
`;

const Pre = styled.pre`
  padding: 1rem;
  margin: 2rem 0;
  display: block;
  border-radius: 5px;
  overflow-x: auto;
  font-family: 'Roboto Mono', monospace;
  font-size: 1.5rem;
  background-color: ${props => props.theme.colorPrimaryDark}66;
`;

const Code = styled.code`
  padding: 0 0.5rem;
`;

const Ul = styled.ul`
  margin-left: 4rem;
  padding-left: 0;
  margin-bottom: 2rem;
`;

const Ol = styled.ol`
  margin-left: 4rem;
  padding-left: 0;
  margin-bottom: 2rem;
`;

const Blockquote = styled.blockquote`
  margin: 2rem;
  padding-left: 1rem;
  font-style: italic;
  border-left: 1rem solid ${props => props.theme.colorPrimaryDark};
  text-shadow: 1px 1px 0px ${props => props.theme.colorPrimaryLight}; ;
`;

const A = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  &:link,
  &:visited,
  &:hover,
  &:active {
    color: ${props => props.theme.colorSecondaryLight};
  }
`;

const Audio = styled.audio.attrs({
  controlsList: 'nodownload',
})`
  padding: 1.2rem;
  border-radius: 0;
  width: 100%;
`;

const Img = styled.img`
`;

export const query = graphql`
  query ($slug: String!) {
    contentfulProjectsPost(slug: { eq: $slug }) {
      title
      tags
      githubLink
      updatedAt(formatString: "MMMM Do. YYYY")
      body {
        childMdx {
          body
        }
      }
    }
  }
`;

const components = {
  p: Paragraph,
  pre: Pre,
  code: Code,
  ul: Ul,
  ol: Ol,
  blockquote: Blockquote,
  a: A,
  audio: Audio,
  img: Img,
};

const ProjectsPost = props => {
  const postData = props.data.contentfulProjectsPost;

  return (
    <Container>
      <TitleBarContainer>
        <Title>{postData.title}</Title>
        <TagsContainer>
          {postData.tags.map(tag => (
            <Tag key={tag}>{tag}</Tag>
          ))}
          {postData.githubLink !== null &&
          <GitHubLink href={postData.githubLink} target="_blank">
            <FaGithub />
          </GitHubLink>}
        </TagsContainer>
        <TimeInfo>Last updated: {postData.updatedAt}</TimeInfo>
      </TitleBarContainer>
      <MDXProvider components={components}>
        <MDXRenderer>{postData.body.childMdx.body}</MDXRenderer>
      </MDXProvider>
      <BackButton to="/projects">back</BackButton>
    </Container>
  );
};

ProjectsPost.Layout = Layout;

export default ProjectsPost;
